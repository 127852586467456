import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class WorkersCompensationInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Workers' Compensation - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "When someone is hurt on the job it can take valuable time and man power from serving customers and keeping your company in Louisiana running. Workers’ Compensation helps ease the burden of an injured employee." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'top'}} alt="Construction worker on the job" />
        <Callout
          title="Workers' Compensation"
          content="Employees are the most important asset for any business. But when someone is hurt on the job, it can take valuable time and man power from serving customers and keeping your company in Louisiana running. Workers’ Compensation helps ease the burden of an injured employee."
        />
        <div className="site-body page-content">
          <h2>What Workers’ Compensation Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Employee Work Illness & Injury Expenses.</strong> This can help pay for missed wages and medical expenses for an employee who gets hurt while working. It will also cover funeral expenses if an employee dies on the job.</li>
          <li><strong>State Law Compliance.</strong> Most states require each employee to have Workers' Compensation coverage as soon as they start the job, so this will help you stay compliant with state laws.</li>
          <li><strong>Employee Injury Lawsuits.</strong> If an employee is hurt on the job, and sues to recoup medical expenses this will cover legal expenses.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="$36,551 is the average cost of a workplace injury claim."
            source="National Safety Council"
          />
          <h2>What Workers’ Compensation Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Injuries caused by intoxication or drugs.</li>
          <li>Self-inflicted injuries.</li>
          <li>Injuries from a fight the employee starts.</li>
          <li>Injuries caused by horseplay or company policy violations.</li>
          <li>Off-the-job injuries.</li>
          <li>Injuries claimed after an employee is fired or laid off.</li>
          <li>Independent contractor injuries.</li>
          <li>Wages for a replacement worker.</li>
          <li>OSHA fines.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Workers' Compensation" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default WorkersCompensationInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "workers-compensation-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-6.jpg" }) {
      ...mainBg
    }
  }
`;
